import { formatIntegerToHaveTwoDigits } from './numbers'

export const getDateString = (date: Date | string): string => {
  if (!date) {
    return ''
  }
  let dateValue = date instanceof Date ? date : new Date(date)
  const month = formatIntegerToHaveTwoDigits(dateValue.getUTCMonth() + 1)
  const stringFormat = `${formatIntegerToHaveTwoDigits(
    dateValue.getUTCDate()
  )}/${month}/${dateValue.getUTCFullYear()}`

  return stringFormat
}

export const getDatetimeString = (date: Date): string => {
  return `${date.toLocaleString()}`
}

export const formatDateString = (date: Date): string => {
  const month = formatIntegerToHaveTwoDigits(date.getUTCMonth() + 1)
  const stringFormat = `${date.getUTCFullYear()}-${month}-${formatIntegerToHaveTwoDigits(
    date.getUTCDate()
  )}`

  return stringFormat
}

export const formataData = (value: string | Date | number): string => {
  const data = new Date(value)
  const year = data.getFullYear()
  const month = String(data.getMonth() + 1).padStart(2, '0')
  const day = String(data.getDate()).padStart(2, '0')

  return `${day}/${month}/${year}`
}