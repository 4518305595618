import i18n from "common/i18n"

const getErrorMessage = (error: string) => {
  switch (error) {
    case 'UserAlreadyExistsException':
        return i18n.errors.userAlreadyExists
  }

  return error
}


export { getErrorMessage}