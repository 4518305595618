import useValidation from 'common/hooks/useValidation'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import React, { ChangeEvent, useRef, useState } from 'react'
import { PasswordInterface } from './interfaces'
import { Colors } from 'common/utils/colors'

const Password = (props: PasswordInterface) => {
  const [showPassword, setShowPassword] = useState(false)
  const ref = useRef<HTMLInputElement>()
  const getType = () => (showPassword ? 'text' : 'password')
  const { error, updateValidity, setBlur, setUpdated } = useValidation({
    customValidate: props.customValidate,
    name: props.name,
    ref,
    type: 'password',
    disabled: props.disabled
  })

  return (
    <FormControl
      variant="outlined"
      sx={{ width: '100%', mb: 3 }}
      error={Boolean(error)}
    >
      <InputLabel htmlFor={props.id} color={error ? 'error' : 'primary'}>
        {props.required ? `${props.label} *` : props.label}
      </InputLabel>
      <OutlinedInput
        inputRef={ref}
        id={props.id}
        sx={{
          backgroundColor: '#FBFBFB',
          color: '#0c2a3d',
          '& fieldset': {
            borderWidth: '1.5px',
            borderColor: '#7f95a3'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.azul
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.azul
          }
        }}
        name={props.name}
        label={props.label}
        disabled={props.disabled}
        required={props.required}
        placeholder={props.placeholder}
        type={getType()}
        error={Boolean(error)}
        value={props.value}
        onBlur={(
          e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        ): void => {
          const { value, validity } = e.target
          setBlur(true)
          updateValidity(value, validity)
        }}
        onChange={(
          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ): void => {
          const { value, validity } = e.target
          setUpdated(true)
          updateValidity(value, validity)
          props.onChange && props.onChange(value)
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((prevState) => !prevState)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default Password
