import ButtonMUI, { ButtonProps } from '@mui/material/Button'
import { Colors } from 'common/utils/colors'

const Button = (props: ButtonProps) => {
  return <ButtonMUI {...props}>{props.children}</ButtonMUI>
}

/**
 * 
const SecondaryButton = styled(Button)<ButtonProps>(({ theme }) => {
  const color = theme.palette.secondary.main
  return {
    color: 'secondar',
    backgroundColor: color,
    '&:hover': {
      backgroundColor: 'rgba(52, 52, 52, 0.7)'
    }
  }
})
 */

const SecondaryButton = (props: Omit<ButtonProps, 'variant' | 'color'>) => {
  return (
    <Button variant="contained" color="secondary" {...props}>
      {props.children}
    </Button>
  )
}
const RedButton = (props: Omit<ButtonProps, 'color'>) => {
  return (
    <Button color="error" {...props}>
      {props.children}
    </Button>
  )
}

const BlueButton = (props: Omit<ButtonProps, 'color'>) => {
  return (
    <Button
      {...props}
      sx={{
        width: '100%',
        marginBottom: '1rem',
        backgroundColor: Colors.azul,
        ':hover': { backgroundColor: Colors.azulQuaternario }
      }}
    >
      {props.children}
    </Button>
  )
}
export { SecondaryButton, RedButton, BlueButton }

export default Button
