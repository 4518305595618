const queries = {
  all: ['users'] as const,
  favorites: () => [...queries.all, 'favorite'] as const,
  lists: () => [...queries.all, 'list'] as const,
  list: (filters: string, pageNumber: number, pageSize: number) => [...queries.lists(), { filters, pageSize, pageNumber }] as const,
  details: () => [...queries.all, 'detail'] as const,
  detail: (id: string) => [...queries.details(), id] as const
}

export default queries
