import config from 'common/config'
import Constants from 'common/constants'
import Http from 'common/http'
import { cpfRegex, emailRegex } from 'common/utils/strings'
import { PropertyServiceInterface } from '../properties/interfaces'
import {
  CustomerServiceInterface,
  GetUsersInterface,
  UpdateFavoritePayloadInterface,
  UserParamsServiceInterface,
  UserServiceInterface
} from './interfaces'
import Queries from './queries'
const basePath = `${config.API_URL}/${Constants.API_URl_PREFIX}/users`

const CPFCNPJ_FIELD = 'cpfCnpj'
const EMAIL_FIELD = 'email'
const NAME_FIELD = 'name'

const UsersService = {
  listUsers: (
    pageSize: number,
    pageNumber: number,
    role: string,
    queryParam?: string,
    params?: UserParamsServiceInterface
  ): Promise<GetUsersInterface> => {
    const param = queryParam || ''
    const isQueryParamCPF = cpfRegex.test(param)
    const isQueryParamEmail = emailRegex.test(param)
    const getParamName = () => {
      if (isQueryParamCPF) return CPFCNPJ_FIELD
      if (isQueryParamEmail) return EMAIL_FIELD
      return NAME_FIELD
    }
    const paramName = getParamName()
    const paramValue =
      paramName === CPFCNPJ_FIELD ? param.replaceAll(/\D/gim, '') : param

    let queryParams = ""

    if(role) {
      queryParams += `&role=${role}`
    }

    if(param) {
      queryParams +=`&${paramName}=${paramValue}`
    }

    if(params?.status) {
      queryParams += `&status=${params.status}`
    }

    if(params?.orderBy) {
      queryParams += `&orderBy=${params.orderBy}`
    }

    if(params?.orderDirection) {
      queryParams += `&orderDirection=${params.orderDirection}`
    }

    if(params?.accessProfile) {
      queryParams += `&accessProfile=${params.accessProfile}`
    }

    return Http.get<GetUsersInterface>(
      `${basePath}?skip=${
        pageSize * pageNumber
      }&take=${pageSize}` + queryParams
    )
  },
  getUser: (id: string): Promise<UserServiceInterface> => {
    return Http.get<UserServiceInterface>(`${basePath}/${id}`)
  },
  createUser: (
    user: Omit<UserServiceInterface, 'id' | 'status'>
  ): Promise<UserServiceInterface> => {
    return Http.post<
      Omit<UserServiceInterface, 'id' | 'status'>,
      UserServiceInterface
    >(basePath, user)
  },
  editUser: (
    user: Partial<UserServiceInterface>
  ): Promise<UserServiceInterface> => {
    return Http.put<Partial<UserServiceInterface>, UserServiceInterface>(
      `${basePath}/${user.id}`,
      user
    )
  },
  deleteUser: (
    id: string
  ): Promise<any> => {
    return Http.remove<void, void>(
      `${basePath}/${id}`,
    )
  },
  createCustomer: (
    customer: CustomerServiceInterface
  ): Promise<{
    auth: string
    user: UserServiceInterface
    refreshToken: string
  }> => {
    return Http.post<
      CustomerServiceInterface,
      { auth: string; user: UserServiceInterface; refreshToken: string }
    >(`${basePath}/customer`, { ...customer, rentalRoles: [], roles: [] })
  },
  listFavorites: (userId: string) => {
    return Http.get<{ realStates: PropertyServiceInterface[] }>(
      `${basePath}/${userId}/favorites`
    )
  },
  addFavorite: (userId: string, propertyId: string) => {
    return Http.put<
      UpdateFavoritePayloadInterface,
      UpdateFavoritePayloadInterface
    >(`${basePath}/${userId}/favorites`, { realStates: [propertyId] })
  },
  removeFavorite: (userId: string, propertyId: string) => {
    return Http.remove<
      UpdateFavoritePayloadInterface,
      UpdateFavoritePayloadInterface
    >(`${basePath}/${userId}/favorites`, { realStates: [propertyId] })
  },
  getAllUsers: () => {
    return Http.get<any[]>(`${basePath}/all`)
  }
}

export default UsersService

export { Queries }
