import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { useAuth } from 'common/auth/auth-provider'
import Logo from 'components/ui/logo'
import { LogoTypeEnum } from 'components/ui/logo/interfaces'
import UserDetailForm from 'components/ui/user-detail-form'
import { useState } from 'react'
import ThemeAdmin from 'styles/theme-admin'
import ThemeSite from 'styles/theme-site'
import ConfirmForgotPasswordForm from './ConfirmForgotPassword'
import ForgotPasswordForm from './ForgotPasswordForm'
import LoginForm from './LoginForm'
import { LoginActionEnum, LoginActionType } from './interfaces'
import Constants from 'common/constants'
import { CircularProgress } from '@mui/material'

const formClass = {
  borderRadius: '0.25rem',
  display: 'flex',
  flexDirection: 'column',
  m: '0 auto',
  maxWidth: '480px',
  width: '480px',
  p: '2rem'
}

export default function Login({initialAction = LoginActionEnum.login}: {initialAction?: LoginActionEnum}) {
  const theme = useTheme()
  const [action, setAction] = useState<LoginActionType>(initialAction)
  const queryparams = new URLSearchParams(window.location.search)
  const isAdminModule = Boolean(queryparams.get('admin'))
  const auth = useAuth()
  const ComponentWrapper = () => {
    switch (action) {
      case LoginActionEnum.login:
        return (
          <LoginForm
            isAdminModule={isAdminModule}
            onClickForgotPasswordButton={() =>
              setAction(LoginActionEnum.forgotPassword)
            }
            onClickCreateUserButton={() => {
              setAction(LoginActionEnum.createUser)
            }}
          />
        )

      case LoginActionEnum.forgotPassword:
        return (
          <ForgotPasswordForm
            isAdminModule={isAdminModule}
            onClickLoginButton={() => setAction(LoginActionEnum.login)}
            goToConfirmPassword={() =>
              setAction(LoginActionEnum.confirmPassword)
            }
          />
        )

      case LoginActionEnum.confirmPassword:
        return (
          <ConfirmForgotPasswordForm
            isAdminModule={isAdminModule}
            onClickForgotPasswordButton={() =>
              setAction(LoginActionEnum.forgotPassword)
            }
            gotoLogin={() => setAction(LoginActionEnum.login)}
          />
        )

      case LoginActionEnum.createUser:
        return (
          <UserDetailForm
            isCreateCustomer={true}
            onCancel={() => {
              setAction(LoginActionEnum.login)
            }}
            onSuccess={(_updatedUser, token, refreshToken) => {
              if (token) {
                auth.loginWithToken(token, refreshToken || '')
              }
            }}
            onClose={() => {
              setAction(LoginActionEnum.login)
            }}
          />
        )
      case LoginActionEnum.loadingPermisions:
        return <Box display={'flex'} alignItems={'center'} justifyContent={'center'}><CircularProgress color='error'/></Box>
    }
  }

  const Theme = isAdminModule ? ThemeAdmin : ThemeSite

  return (
    <Theme>
      <Box
        sx={{
          height: '100vh',
          justifyContent: 'end',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          backgroundImage: `url(${Constants.BACKGROUND_IMAGE_LOGIN})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Box
          sx={{
            height: '100vh',
            justifyContent: 'center',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              color: isAdminModule
                ? theme.palette.secondary.contrastText
                : theme.palette.primary.dark,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <Logo
              type={isAdminModule ? LogoTypeEnum.LIGHT : LogoTypeEnum.DARK}
            />
          </Box>
          <Box sx={formClass}>
            <Theme>
              <ComponentWrapper />
            </Theme>
          </Box>
          {/* <Box sx={copyrightClass}>
            <p>{I18n.login.copyright(currentDate.getFullYear())}</p>
          </Box> */}
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${Constants.IMAGE_YOUX})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: 100
          }}
        ></Box>
      </Box>
    </Theme>
  )
}
