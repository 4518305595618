import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material/styles'
import type {} from '@mui/lab/themeAugmentation'
import { ThemeInterface } from './interfaces'

const colors = {
  mainBlack: '#343434',
  mainRed: '#d32f2f',
  lightRed: '#f0625b',
  white: '#ffffff'
}
const themeOptions = createTheme({
  palette: {
    primary: {
      light: colors.lightRed,
      main: colors.mainRed,
      contrastText: colors.white
    },
    secondary: {
      main: colors.mainBlack,
      contrastText: colors.white
    }
  }
} as ThemeOptions)

export default function Theme(props: ThemeInterface) {
  return <ThemeProvider theme={themeOptions}>{props.children}</ThemeProvider>
}
