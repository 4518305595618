import TextField from '@mui/material/TextField'
import { ChangeEvent, useRef, useEffect } from 'react'
import { InputInterface } from './interfaces'
import Box from '@mui/system/Box'
import useValidation from 'common/hooks/useValidation'
import { Colors } from 'common/utils/colors'

const Input = (props: InputInterface) => {
  const ref = useRef<HTMLInputElement>()
  const { error, updateValidity, setBlur, setUpdated } = useValidation({
    customValidate: props.customValidate,
    min: props.min,
    minLength: props.minLength,
    max: props.max,
    maxLength: props.maxLength,
    name: props.name || props.id,
    noValidate: props.noValidate,
    ref,
    type: props.type,
    disabled: props.disabled,
    step: props.step,
    multiline: props.multiline
  })

  useEffect(() => {
    setUpdated(true)
  }, [props])

  return (
    <Box sx={props.noValidate ? {} : { marginBottom: 3 }}>
      <TextField
        multiline={props.multiline}
        autoFocus={props.autoFocus}
        value={props.value || props.defaultValue}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        error={Boolean(error)}
        fullWidth={props.fullWidth ?? true}
        helperText={error}
        id={props.id}
        InputProps={{
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment
        }}
        inputProps={{
          max: props.max,
          maxLength: props.maxLength,
          min: props.min,
          minLength: props.minLength,
          pattern: props.pattern,
          step: props.step,
          inputMode: props.inputMode
        }}
        sx={
          props.searchBorder
            ? {
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#FBFBFB',
                  color: '#0c2a3d',
                  '& fieldset': {
                    borderWidth: '1.5px',
                    borderColor: '#7f95a3'
                  },
                  '&:hover': {
                    '& fieldset': {
                      borderWidth: '1.5px',
                      borderColor: Colors.azul
                    }
                  },
                  '&.Mui-focused': {
                    '& fieldset': {
                      borderWidth: '1.5px',
                      borderColor: Colors.azul
                    }
                  }
                }
              }
            : {}
        }
        inputRef={ref}
        label={props.label}
        name={props.name || props.id}
        onBlur={(
          e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        ): void => {
          const { value, validity } = e.target
          setBlur(true)
          updateValidity(value, validity)
          props.onBlur && props.onBlur(value)
        }}
        onChange={(
          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ): void => {
          const { value, validity } = e.target
          setUpdated(true)
          updateValidity(value, validity)
          props.onChange && props.onChange(value)
        }}
        placeholder={props.placeholder}
        required={props.required}
        size={props.size}
        type={props.type}
        variant={props.variant}
      />
    </Box>
  )
}

export default Input
