import {
  ErrorMappingInterface,
  ValidationFormErrorInterface
} from './interfaces'
import { formataData

 } from 'common/utils/date';
const translations = {
  addressType: {
    avenue: 'Avenida',
    garden: 'Jardim',
    patio: 'Pátio',
    plaza: 'Praça',
    square: 'Quadra',
    street: 'Rua'
  },
  agency: {
    cnpj: 'CNPJ',
    edit: 'Editar dados da imobiliária',
    name: 'Nome',
    title: 'Imobiliária'
  },
  leads: {
    title: 'Leads',
    add: 'Cadastrar lead',
    edit: 'Editar lead',
    gestao: 'Gestão de Leads',
    historico: 'Histórico de Leads',
    listagemHistorico: 'Listagem de histórico',
    data: 'Data',
    codImovel: 'Cód do imóvel',
    status: 'Status',
    nome: 'Nome',
    actions: 'Ações',
    cadastrar: 'Cadastrar Lead',
    imovel: 'Imóvel',
    telefone: 'Telefone',
    origem: 'Origem',
    whatsApp: 'WhatsApp',
    interesse: 'Interesse',
    cancelar: 'Cancelar',
    salvar: 'Salvar Lead',
    buscar: 'Buscar'
  },
  banners: {
    commercialType: {
      button: 'Ver todos os imóveis',
      title: 'Olá empreendedor!',
      titleComplement: 'Procura um local para sua empresa?'
    },
    helloStudent: {
      button: 'Ver todos os imóveis',
      title: 'Olá estudante!',
      titleComplement: 'Procura um apê bem localizado?'
    },
    rightPlace: {
      button: 'Quero cadastrar meu imóvel',
      title: 'O lugar certo para a valorização do seu imóvel.'
    },
    simpleRent: {
      button: 'Cadastre seu imóvel',
      title: 'Alugue seu imóvel de forma rápida e simples',
      subtitle: 'Alugar seu imóvel nunca foi tão fácil'
    },
    quickRent: {
      button: 'Faça uma proposta',
      title: 'Agilidade e facilidade',
      subtitle:
        'Sem burocracia na hora de alugar um imóvel próximo de faculdades e mercados'
    }
  },
  contracts: {
    add: 'Novo contrato',
    copasaCemig: 'Copasa/Cemig',
    create: 'Gerar contrato',
    deliveryOfKeys: 'Entrega das chaves e manual',
    digitalizedContract: 'Contrato digitalizado',
    edit: 'Editar Contrato',
    entryInspection: 'Inspeção',
    entryInspectionSignature: 'Aprovação da Inspeção',
    registrationData: 'Dados do contrato',
    rentPaymentDay: 'Dia do pagamento',
    status: {
      filling: 'Em Preenchimento',
      active: 'Ativo',
      in_legal: 'Jurídico',
      in_vacancy: 'Em Desocupação',
      terminated: 'Rescindido'
    } as Record<string, string>,
    tenantAssignedContract: 'Assinatura do locatário',
    tenantBirthplace: 'Local de nascimento',
    tenantCountryOfBirth: 'Nacionalidade',
    tenantDocuments: 'Documentos do locatário',
    tenantMaritalStatus: 'Estado civil',
    tenantOccupation: 'Ocupação',
    title: 'Contratos',
    open: 'Abrir contrato',
    ownerAssignedContract: 'Assinatura do locador'
  },
  dashboard: {
    activeProperties: 'Imóveis ativos',
    activeContracts: 'Contratos ativos',
    canceledContracts: 'Contratos cancelados',
    closedContracts: 'Contratos fechados',
    realStateCollected: 'Imóveis captados',
    lastCreatedProperties: 'Últimos imóveis cadastrados',
    newProperties: 'Imóveis captados',
    title: 'Dashboard'
  },
  employee: {
    add: 'Novo funcionário',
    edit: 'Editar funcionário'
  },
  errors: {
    currencyRegex: 'Formato inválido para um campo monetário - X,XX',
    errorBoundary: 'Ops... Aconteceu um erro... Tente novamente.',
    expiredToken: 'Token expirado',
    fileLimit: (size: string) => `O tamanho máximo do arquivo deve ser ${size}`,
    floatRegex: 'Formato inválido para um campo percentual - X,XX',
    userAlreadyExists: 'Já existe um usuário com este e-mail',
    missingPermission: 'Você não possui permissão para acessar este recurso'
  },
  financial: {
    title: 'Financeiro'
  },
  form: {
    errors: {
      badInput: () => `Formato inválido`,
      patternMismatch: (error: ValidationFormErrorInterface) =>
        `${inputNameFormat(error.inputName)} inválido`,
      tooLong: (error: ValidationFormErrorInterface) =>
        `Digite até ${error.maxLength} caracteres`,
      tooShort: (error: ValidationFormErrorInterface) =>
        `Digite pelo menos ${error.minLength} caracteres`,
      valueMissing: () => `Campo obrigatório`,
      rangeOverflow: (error: ValidationFormErrorInterface) => {
        if (error.inputType === 'date' && error.max !== undefined && error.max) {
          const formattedDate = formataData(error.max);
          return `Deve ser menor ou igual a ${formattedDate}`
        } else {
          return `Deve ser menor ou igual que ${error.max}`;
        }
      },
      rangeUnderflow: (error: ValidationFormErrorInterface) => {
        if (error.inputType === 'date' && error.min !== undefined && error.min) {
          const formattedDate = formataData(error.min);
          return `Deve ser maior ou igual a ${formattedDate}`
        } else {
          return `Deve ser maior ou igual que ${error.min}`;
        }
      },
      stepMismatch: (error: ValidationFormErrorInterface) =>
        `Menor valor possível é ${error.step}`,
      typeMismatch: (error: ValidationFormErrorInterface) =>
        `Digite um ${error.inputType}`
    } as ErrorMappingInterface,
    reset: 'Limpar',
    submit: 'Enviar'
  },
  general: {
    active: 'Ativo',
    add: 'Adicionar',
    addPhoto: 'Adicionar nova foto',
    addFiles: 'Adicionar novos arquivos',
    address: 'Endereço',
    all: 'Todos',
    back: 'Voltar',
    birthdate: 'Data de nascimento',
    cancel: 'Cancelar',
    apply: 'Aplicar',
    category: 'Categoria',
    clear: 'Limpar',
    checkAll: 'Marcar todos',
    checkDetails: 'Ver detalhes',
    city: 'Cidade',
    close: 'Fechar',
    code: 'Código',
    contact: 'Contato',
    contracts: 'Contratos',
    createdAt: 'Criado em',
    data: 'Dados',
    dataSavedSuccess: 'Dados salvos com sucesso!',
    description: 'Descrição',
    details: 'Detalhes',
    documents: 'Documentos',
    edit: 'Editar',
    email: 'E-mail',
    phone: 'Telefone',
    endDate: 'Data de término',
    filter: 'Filtros',
    initDate: 'Data de início',
    justification: 'Justificativa',
    lastUpdatedAt: 'Última atualização',
    leasedRealState: 'Imóveis locados',
    logo: 'SeuLugar Logo',
    logout: 'Sair',
    maxPrice: 'Preço máximo',
    month: 'Mês',
    name: 'Nome',
    next: 'Próximo',
    noResultsFound: 'Não encontramos resultados para a sua busca...',
    notFilled: 'Não informado',
    number: 'Número',
    observation: 'Observações',
    openNewTab: 'Abrir',
    ownProperties: 'Imóveis próprios',
    photos: 'Fotos',
    price: 'Preço',
    prices: 'Preços',
    proposals: 'Propostas',
    property: 'Imóvel',
    rejectReason: 'Motivo de recusa',
    removePhoto: 'Remover foto',
    removeFile: 'Remover arquivo',
    save: 'Salvar',
    search: 'Buscar',
    select: 'Selecionar',
    send: 'Enviar',
    state: 'Estado',
    status: 'Situação',
    type: 'Tipo',
    update: 'Atualizar',
    updatedAt: 'Atualizado em',
    uncheckAll: 'Desmarcar todos',
    deleteProperty: 'Excluir imóvel',
    infoPhoto: 'Clique em uma foto para usar como capa',
    saveProperty: 'Salvar imóvel',
    priceNegotiation: 'Preço e Negociação',
    amenities: 'Comodidades',
    imagesAndDocuments: 'Imagens e Documentos',
    pickup: 'Captador',
    propertyPublication: 'Publicação do Imóvel',
    errorZipcode: 'Erro ao buscar dados',
    requiredFields: 'Preencha todos os campos obrigatórios',
    yes: 'Sim',
    no: 'Não',
    warningTitle: 'Atenção',
    cancelMessage: 'Ao cancelar, os dados serão perdidos e será necessário realizar o cadastro novamente.',
    proceed: 'Prosseguir',
    noInformation: 'Não há informações disponíveis',
    view: 'Visualizar',
    delete: 'Excluir',
    your: 'Seu',
    saveChanges:'Salvar alterações'
  },
  login: {
    copyright: (year: number) =>
      `© ${year} - seu.lugar - Todos os direitos reservados`,
    createUser: 'Criar conta Seu.Lugar',
    errors: {
      passwordMustContainNumber: `A senha deve conter pelo menos um número`,
      passwordMustContainUpperCase: `A senha deve conter pelo menos uma letra maiúscula`,
      passwordMustContainLowerCase: `A senha deve conter pelo menos uma letra minúscula`,
      passwordMustHaveLength: (length: number) =>
        `A senha deve conter pelo menos ${length} caracteres`,
      passwordMustMatch: 'As senhas não são iguais'
    },
    forgotPassword: 'Esqueci minha senha',
    password: 'Senha',
    newPassword: 'Nova Senha',
    confirmNewPassword: 'Confirmar Nova Senha',
    recoverPassword: 'Recuperar senha',
    signin: 'Entrar'
  },
  properties: {
    add: 'Novo imóvel',
    addNewProposal: 'Criar proposta',
    address: 'Endereço do imóvel',
    addressType: 'Tipo',
    additionalParkingSpace: 'Vaga adicional de garagem',
    alreadyHaveProposal: 'Já possui uma proposta ativa',
    administrationFee: 'Taxa de administração',
    approximateValue: 'Valor aproximado',
    area: 'Área',
    bailInsurance: 'Seguro fiança',
    bathrooms: 'Banheiros',
    categories: {
      commercial: 'Comercial',
      residential: 'Residencial'
    } as Record<string, string>,
    checkWebsite: 'Ver imóvel no site',
    condominium: 'Condomínio',
    description: 'Dados do imóvel',
    edit: 'Editar imóvel',
    estimated: 'Estimados',
    footage: 'Metragem',
    fireInsurance: 'Seguro incêndio',
    includedInCondominium: 'Itens inclusos no condomínio',
    intermediationFee: 'Taxa de intermediação',
    iptu: 'IPTU',
    itemsIncludedInCondominium: {
      reserveFund: 'Fundo de reserva',
      water: 'Água'
    },
    invalidZipCode: 'Formato de CEP inválido',
    locationReference: 'Complemento',
    minimumPrice: 'Valor mínimo aceito',
    neighborhood: 'Bairro',
    noPhoto: 'Arraste as fotos do imóvel para esse quadro ou clique no botão abaixo para selecionar',
    noFile: 'Ainda não há arquivos adicionados',
    addFile: 'Arraste os documentos do imóvel para esse quadro ou clique no botão abaixo para selecionar',
    parkingSpaces: 'Vagas de garagem',
    propertyData: 'Dados do imóvel',
    propertyDoesNotHaveContracts: 'O imóvel não possui contratos',
    propertyDoesNotHaveActiveContract: 'O imóvel não possui contrato ativo',
    propertyDoesNotHaveProposals: 'O imóvel não possui propostas',
    propertyNotRented: 'O imóvel ainda não foi locado',
    propertyNotOwned: 'O imóvel não possui dono',
    otherValues: 'Demais valores',
    rent: 'Aluguel',
    registerPayment: 'Registrar pagamento',
    rooms: 'Quartos',
    kitchens: 'Cozinhas',
    sendPhoto: 'Enviar fotos',
    status: {
      available: 'Disponível para locação',
      awaitingSignature: 'Aguardando assinaturas',
      inactive: 'Inativo',
      pendingPhoto: 'Aguardando fotos',
      active: 'Ativo',
      pending: 'Pendente',
      proposalInAnalysis: 'Proposta em análise',
      rent: 'Imóvel locado'
    },
    targetPrice: 'Valor anunciado',
    title: 'Imóveis',
    total: 'Total',
    types: {
      apartment: 'Apartamento',
      house: 'Casa',
      kitnet: 'Kitnet',
      land: 'Terreno',
      room: 'Sala',
      shed: 'Galpão',
      store: 'Loja',
      studio: 'Studio'
    } as Record<string, string>,
    zipCode: 'CEP',
    propertyOwner: 'Proprietário',
    indicateOrRegister: 'Deseja  indicar ou cadastrar um imóvel?',
    indicate: 'Indicar',
    register: 'Cadastrar',
    mensageNewPhoto: 'Para adicionar uma foto capa, clique em alguma image',
    mensagePhotoIsPrimary:
      'Para marcar uma nova foto como capa, delete a capa atual!',
    infoOwner: 'Informar proprietário',
    notInfoOwner: 'Não informar proprietário'
  },
  proposals: {
    add: 'Nova proposta',
    edit: 'Editar proposta',
    title: 'Propostas',
    proposalData: 'Dados da proposta',
    status: {
      accepted: 'Aceita',
      filling: 'Em Preenchimento',
      insuranceAnalysis: 'Em Análise - Seguro',
      valueAnalysis: 'Em Análise - Valor',
      rejected: 'Recusada'
    } as Record<string, string>
  },
  roles: {
    admin: 'Administrador',
    customer: 'Usuário',
    employee: 'Funcionário'
  } as Record<string, string>,
  settings: {
    title: 'Configurações'
  },
  site: {
    home: {
      commercialProperties: {
        title: 'Imóveis comerciais',
        description:
          'Você pode encontrar apartamentos, casas e pontos comerciais para aluguel.',
        link: 'Quero ver todos os pontos comerciais'
      },
      lastRegisteredProperties: {
        description:
          'Nossos imóveis são todos certificados por profissionais da área e cada um deles tem sua documentação válida para aluguel.',
        title: 'Últimos imóveis cadastrados'
      },
      seeAlso: {
        description:
          'Você pode encontrar apartamentos, casas e pontos comerciais para aluguel.',
        title: 'Veja também',
        link: 'Quero ver todos os imóveis'
      }
    },
    favorites: {
      favoriteList: 'Lista de imóveis favoritos',
      favoriteListDescription:
        'Estes são os imóveis que você mais gostou e salvou para analisar depois.',
      mainBannerTitle: 'Seus imóveis favoritos',
      mainBannerSubtitle: 'Veja seus "queridinhos" da plataforma'
    },
    filter: {
      title: 'Imobiliária',
      subTitle: 'descomplicada',
      search: 'Filtro'
    },
    logo: {
      text: 'Seu imóvel sem burocracia'
    }
  },
  users: {
    add: 'Novo usuário',
    cpfCnpj: 'CPF/CNPJ',
    documents: 'Documentos',
    edit: 'Editar usuário',
    errors: {
      cpfFormat: 'Somente números ou XXX.XXX.XXX-XX',
      validContact: 'Somente números ou (XX)YXXXX-XXXX',
      duplicateContact:
        'Esse número de contato já foi adicionado anteriormente. Por favor, verifique sua lista de contatos.'
    },
    fullName: 'Nome/Razão social',
    new: 'Cadastrar usuário',
    newest: 'Mais recentes',
    noPhones: 'Não há telefones cadastrados',
    ownedProperties: 'Imóveis próprios',
    permissions: 'Permissões',
    personalData: 'Dados pessoais',
    ownerData: 'Dados do proprietário',
    phones: 'Telefones',
    phone: 'Telefone',
    registrationData: 'Dados cadastrais',
    rentedProperties: 'Imóveis alugados',
    rg: 'RG',
    role: 'Papel',
    search: 'Busque por um usuário',
    status: {
      active: 'Ativo',
      inactive: 'Inativo'
    } as Record<string, string>,
    title: 'Usuários',
    user: 'Usuário',
    types: {
      owner: 'Proprietário',
      tenant: 'Locatário'
    } as Record<string, string>,
    userNotOwnerProperty: 'O usuário não é dono de imóvel',
    userNotRentedProperty: 'O usuário não tem imóvel alugado',
    viewDetails: 'Ir para detalhes do usuário',
    otherInfo: 'Informações adicionais',
    saveAndAdvance: 'Salvar e avançar',
    registerOwner: 'Cadastrar proprietário',
    name: 'Nome',
    email: 'Email',
    birthdate: 'Data de nascimento',
    whatsApp: 'WhatsApp',
    accessProfile: 'Perfil de acesso',
    seeSummary: 'Ver resumo',
    management: 'Gestão de Usuários',
    profileType: 'Tipo de perfil',
    renter: 'Locatário',
    ownProperties: 'Imóveis próprios',
    noPropertiesRented: 'O usuário não tem imóvel alugado',
    noOwnProperty: 'O usuário não é dono de imóvel'
  },
  access: {
    management: 'Gestão de acesso',
    profile: 'Perfil',
    profileName: 'Nome do perfil',
    accessType: 'Tipo de acesso',
    permissions: 'Permissões',
    accessTypeEnum: {
      EXTERNAL: 'Externo',
      INTERNAL: 'Interno'
    },
    permissionsEnum: {
      REAL_STATE_REGISTRATION: 'Cadastro de imóvel',
      REAL_STATE_EDITING: 'Edição de imóvel',
      REAL_STATE_INACTIVATION: 'Desativação de imóvel',
      REAL_STATE_ACTIVATION: 'Ativação de imóvel',
      REAL_STATE_DELETE: 'Exclusão de imóvel',
      USER_REGISTRATION: 'Cadastro de usuário',
      USER_EDITING: 'Edição de usuário',
      USER_INACTIVATION: 'Desativação de usuário',
      USER_ACTIVATION: 'Ativação de usuário',
      USER_DELETE: 'Exclusão de usuário',
      ACCESS_PROFILE_REGISTRATION: 'Cadastro de perfil de acesso',
      ACCESS_PROFILE_EDITING: 'Edição de perfil de acesso',
      ACCESS_PROFILE_INACTIVATION: 'Desativação de perfil de acesso',
      ACCESS_PROFILE_ACTIVATION: 'Ativação de pefil de acesso',
      ACCESS_PROFILE_DELETE: 'Exclusão de perfil de acesso',
    }
  },
  visits: {
    title: 'Visitas'
  },
  stepsOwner: {
    searchOwner: 'Buscar Proprietário',
    searchNameCpf: 'Busque por nome ou cpf já cadastrado',
    registerOwner: 'Cadastrar proprietário',
    registerNewOwner: 'Cadastrar novo proprietário',
    name: 'Nome',
    phoneOne: 'Telefone 1',
    phoneTwo: 'Telefone 2',
    WhatsApp: 'WhatsApp',
    email: 'Email',
    cpfCnpj: 'CPF/CNPJ',
    rg: 'RG',
    birthdate: 'Data de nascimento',
    saveOwner: 'Salvar Proprietário'
  },
  condominiumLabels: {
    title: 'Condomínios cadastrados',
    name: 'Nome',
    add: 'Novo condomínio',
    responsible: 'Responsável',
    contact: 'Contato',
    numbersOfImoveis: 'Número de Imóveis',
    neighborhood: 'Bairro',
    actions: 'Ações',
    last: 'Mais recente',
    older: 'Mais antigo'
  },
  comodidadesTitle: {
    imovelOferece: 'O que seu Imóvel oferece?',
    caracteristicasImovel: 'Quais as características do imóvel?',
    tipoLazerImovel: 'Qual tipo de lazer do imóvel?',
    loalizacaoArmarios: 'Onde estão instalados os armários?',
    tipoAquecimento: 'Qual tipo de aquecimento?',
    tipoDePiso: 'Qual tipo de piso',
    tipoLazerCondominio: 'Qual lazer do condomínio?',
    infraCondominio: 'Qual a infraestrutura do seu condomínio?'
  },
  assortmentFilter: {
    show: 'Exibir',
    ordenation: 'Ordenação',
    showOptions: {
      all: 'Todos'
    },
    orderOptions: {
      latest: 'Mais recente primeiro',
      older: 'Mais antigo primeiro',
      lowestValue: 'Menor valor primeiro',
      greatestValue: 'Maior valor primeiro',
      sale: 'Venda',
      rent: 'Locação'
    }
  },
  advancedFilter: {
    federalityUnity: 'Unidade Federativa',
    city: 'Cidade',
    neighborhood: 'Bairros',
    min: 'Mínimo',
    max: 'Máximo',
    tipoImovel: 'Tipo do Imóvel',
    finalidade: 'Finalidade',
    status: 'Status',
    qtdQuartos: 'Quantidade de Quartos',
    value: 'Valor'
  },
  propertyDescription: {
    cep: 'CEP',
    notCep: 'Não sei meu CEP',
    publicPlace: 'Logradouro',
    number: 'Número',
    neighborhood: 'Bairro',
    block: 'Bloco',
    locationReference: 'Complemento',
    city: 'Cidade',
    state: 'Estado',
    condominium: 'Condomínio',
    selectCondominium: 'Selecione o condomínio',
    registerCondominium: 'Cadastrar condomínio',
    cantSay: 'Não sei dizer',
    commercialDistrict: 'Bairro comercial',
    detailedInformation: 'Informações detalhadas',
    address: 'Endereço',
    purposeProperty: 'Finalidade do imóvel',
    propertyType: 'Tipo do imóvel',
    propertySubtype: 'Subtipo do imóvel',
    totalArea: 'Área total',
    totalAreaTooltip: 'Área total é a soma de todas as áreas do imóvel, sendo ela maior que as demais',
    usefulArea: 'Área útil',
    usefulAreaTooltip: 'Área útil é aquela interior ao imóvel, sendo a somatória das áreas dos cômodos internos desconsiderando garagens, varandas e paredes',
    privateArea: 'Área privativa',
    commonArea: 'Área comum',
    commonAreaTooltip: 'São áreas com acesso comum, tanto dos moradores quanto dos convidados. São considerados, por exemplo, saguões, áreas de lazer e piscinas no cálculo',
    rightFoot: 'Pé direito',
    solarPosition: 'Posição Solar',
    floorNumber: 'Número do andar',
    occupation: 'Ocupação',
    locationKeys: 'Local das chaves do imóvel',
    specifyBelow: 'Especifique abaixo',
    petAccepted: 'Aceita pet',
    furnished: 'Mobiliado',
    characteristics: 'Características',
    bedrooms: 'Quartos',
    kitchens: 'Cozinhas',
    howManySuites: 'Sendo quantas suítes',
    bathroom: 'Banheiros',
    bathroomTooltip: 'Considerando suítes, se existirem',
    toilet: 'Lavabos',
    rooms: 'Salas',
    parkingSpaces: 'Vagas de garagem',
    garageIdentifier: 'Identificador da vaga de garagem',
    description: 'Descrição do imóvel'
  },
  priceNegotiation: {
    sale: 'Venda',
    rent: 'Locação',
    businessType: 'Tipo de negócio',
    youCanSelectBothOptions: 'Você pode selecionar as duas opções',
    saleValue: 'Valor de venda',
    proprietaryFreeValue: 'Valor livre do proprietário',
    rentalValue: 'Valor da locação',
    tax: 'Taxas/Impostos',
    condominiumValue: 'Valor do condomínio',
    IptuValue: 'Valor do IPTU',
    ItrValue: 'Valor do ITR',
    IptuInstallment: 'Parcelamento do IPTU',
    warrantyTypes: 'Tipos de garantia',
    negotiation: 'Negociação',
    minimumSalesValue: 'Valor mínimo de venda',
    maximumSalesValue: 'Valor máximo de venda',
    acceptFinancing: 'Aceita financiamento',
    minimumRentalAmount: 'Valor mínimo da locação',
    maximumRentalAmount: 'Valor máximo da locação',
    acceptExchange: 'Aceita permuta',
    administrationFee: 'Taxa de administração',
    proprietaryFreeValueWithAdministrationFee: 'Valor livre do proprietário pós taxa de administração',
    intermediationFee: 'Taxa de intermediação',
    negotiationDetails: 'Detalhes da negociação',
    placeholderTextArea:
      'Informe aqui alguma particularidade da negociação, como por exemplo, imóvel de herança, financiamento pendente e etc...',
    confidentialInformation: 'Informações confidenciais',
    numberIptu: 'Número do IPTU',
    propertyRegistration: 'Matrícula do imóvel',
    energyMeterCode: 'Código do medidor de energia',
    waterMeterCode: 'Código do hidrômetro',
    gasMeterCode: 'Código do medidor de gás',
    writingSituation: 'Situação da escritura',
    registryOffice: 'Cartório de registro',
    dwell: 'Habite-se',
    exempt: 'Isento'
  },
  propertyPublication: {
    terms: 'Termos',
    hasPublicationTerm: 'Possui termo de publicação?',
    hasPropertySign: 'Possui placa no imóvel?',
    adStatus: 'Status do anúncio',
    sendTerm: 'Enviar termo de aceite ao proprietário',
    announcement: 'Anúncio',
    announcementDescription:
      'Escreva um texto detalhado do seu imóvel, destacando seus pontos fortes e diferenciais',
    title: 'Título',
    placeholderTitle:
      'Digite aqui um título para o anúncio e o card do seu imóvel...',
    propertyDescription: 'Descrição do imóvel',
    placeholderPropertyDescription: 'Faça uma descrição do imóvel...'
  },
  pickup: {
    searchPickup: 'Buscar captador',
    placeholderSearchPickup: 'Pesquise por nome ou cpf já cadastrados',
    isIndication: 'Este imóvel foi indicado por alguém',
    searchCoPickup: 'Buscar Co-captador',
    registerPickup: 'Cadastrar captador',
    registerNewPickup: 'Cadastrar novo captador',
    registerCoPickup: 'Cadastrar co-captador',
    registerNewCoPickup: 'Cadastrar novo co-captador',
    savePickup: 'Salvar captador',
    saveCoPickup: 'Salvar co-captador'
  },
  registerCondominium: {
    new: 'Novo Condomínio',
    edit: 'Editar Condomínio',
    nameCondominium: 'Nome do condomínio',
    administrator: 'Administradora',
    registerAdministrator: 'Cadastrar administradora',
    phone: 'Telefone',
    email: 'Email',
    zipCode: 'CEP',
    neighborhood: 'Bairro',
    typeCondominium: 'Tipo de condomínio',
    saveCondominium: 'Salvar condomínio',
    notCep: 'Não sei meu cep',
    publicPlace: 'Logradouro',
    number: 'Número',
    state: 'Estado',
    referencePoint: 'Ponto de referência',
    city: 'Cidade',
    save: 'Salvar Condomínio'
  },
  propertiesSummary: {
    inactivePropertyTitle: "Imóvel desativado",
    inactivePropertySubtitle: "O imóvel foi desativado porque já foi vendido.",
    inactivateProperty: 'Desativar esse imóvel',
    reactivateProperty: 'Reativar esse imóvel',
    modal: {
      inactivatePropertySubtitle: 'Se você desativar esse imóvel ele não poderá mais ser visto pelos clientes, ' +
        'mas seus dados cadastrais permanecerão salvos.',
      inactivationReasonPlaceholder: 'Informe aqui o motivo da desativação do imóvel...',
      reactivatePropertySubtitle: 'Se você reativar esse imóvel, ele será novamente exibido aos clientes no site.',
      reactivationReasonPlaceholder: 'Informe aqui o motivo da reativação do imóvel...',
      close: 'Fechar',
    },
    sections: {
      general: {
        user: {
          name: 'Nome',
          phone: 'Telefone',
          email: 'E-mail',
          cpfCnpj: 'CPF/CNPJ',
          rg: 'RG',
          dateOfBirth: 'Data de Nascimento',
        }
      },
      owner: {
        sectionTitle: 'Proprietário',
        emptyOwner: 'Não possui informações do proprietário.',
      },
      property: {
        sectionTitle: 'Dados do imóvel',
        address: {
          zipCode: 'CEP',
          street: 'Logradouro',
          number: 'Número',
          neighborhood: 'Bairro',
          city: 'Cidade',
          state: 'Estado',
        },
        block: 'Bloco',
        complement: 'Complemento',
        purpose: 'Finalidade',
        propertyType: 'Tipo do Imóvel',
        propertySubtype: 'Subtipo do imóvel',
        totalArea: 'Área total (m²)',
        usefulArea: 'Área útil (m²)',
        privateArea: 'Área Privada (m²)',
        commonArea: 'Área Comum (m²)',
        ceilingHeight: 'Pé direito (m)',
        solarPosition: 'Posição Solar',
        floor: 'Andar',
        occupation: 'Ocupação',
        keyLocation: 'Local das Chaves do Imóvel',
        acceptsPets: 'Aceita Pet',
        furnished: 'Mobiliado',
        rooms: 'Quartos',
        kitchens:'Cozinhas',
        howManySuites: 'Sendo quantas suítes',
        bathrooms: 'Banheiros',
        washrooms: 'Lavabos',
        livingRooms: 'Salas',
        parkingSpaces: 'Vagas de Garagem',
        garageIdentifier: 'Identificador da vaga de Garagem'
      },
      priceNegotiation: {
        sectionTitle: 'Preço e Negociação',
        rent: 'Locação',
        sale: 'Venda',
        saleValue: 'Valor de Venda',
        rentValue: 'Valor da Locação',
        proprietaryFreeValue: 'Valor Livre do Proprietário',
        condoFee: 'Condomínio',
        propertyTax: 'IPTU',
        ruralPropertyTax: 'ITR',
        propertyTaxInstallment: 'Parcelamento IPTU',
        guaranteeTypes: 'Tipos de Garantia',
        minSaleValue: 'Valor Mínimo de Venda',
        maxSaleValue: 'Valor Máximo de Venda',
        financingAccepted: 'Aceita Financiamento',
        minRentValue: 'Valor Mínimo de Locação',
        maxRentValue: 'Valor Máximo de Locação',
        barterAccepted: 'Aceita Permuta',
        administrationFee: 'Taxa de Administração',
        intermediationFee: 'Taxa de Intermediação',
        propertyTaxNumber: 'Número do IPTU',
        propertyRegistration: 'Matrícula do Imóvel',
        energyMeterCode: 'Código do Medidor de Energia',
        registryOffice: 'Cartório de Registro',
        occupancyPermit: 'Habite-se'
      },
      amenities: {
        sectionTitle: 'Comodidades',
        empty: 'Não ha dados'
      },
      imagesAndDocuments: {
        sectionTitle: 'Imagens e Documentos',
        images: 'Imagens',
        documents: 'Documentos',
        files: 'arquivo(s)',
        coverImage: 'Foto da capa',
        videoUrl: 'Url do video'
      },
      collector: {
        sectionTitle: 'Captador',
        collector: 'captador',
        cooCollector: 'co-captador',
        propertyIsIndicated: 'Esse imóvel foi indicado por alguém'
      },
      propertyPublication: {
        sectionTitle: 'Publicação do Imóvel',
        hasPublicationTerm: 'Possui termos de publicação',
        hasPropertySign: 'Possui placa no imóvel',
        announcementStatus: 'Status do anúncio'
      },
      emptyValue: '-',
      editIconText: 'Editar'
    }
  },
  propertiesInfo: {
    sale: 'Venda',
    location: 'Locação'
  },
  simpleSummary: {
    deleteThis: 'Excluir esse',
    simpleModal: {
      registrationSavedSuccessfully: 'Cadastro salvo com sucesso!',
      hasSuccessfullyRegistered: 'foi cadastrado com sucesso!',
      successfullyRegistered: 'Cadastrado com sucesso!',
      doYouWantToViewThe: 'Deseja visualizar o',
      register: 'cadastro',
      viewRegister: 'Visualizar cadastro'
    },
    close: 'Fechar',
    deleteUser: 'Excluir usuário',
    deleteUserMessage: 'Se você excluir esse usuário, ele não existirá mais dentro do sistema.',
    noChangesOnRegister: 'Não houve alterações no cadastro',
    registrationUpdatedSuccessfully: 'Cadastro atualizado com sucesso!'
  }
}

const inputNameFormat = (inputName: string | undefined) => {
  switch (inputName) {
    case 'email-condominio':
      return 'email'
    default:
      return inputName
  }
}

export default translations
