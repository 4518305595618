import I18n from 'common/i18n'
import Input from 'components/ui/form/input'
import { BlueButton } from 'components/ui/button'
import Form from 'components/ui/form'
import Password from 'components/ui/form/password'
import { LoginFormInterface } from './interfaces'
import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useAuth } from 'common/auth/auth-provider'
import Alert from '@mui/material/Alert'
import Box from '@mui/system/Box'
import { useSearchParams } from 'react-router-dom'
// import Divider from '@mui/material/Divider'

export default function LoginForm({
  onClickForgotPasswordButton,
  onClickCreateUserButton,
  isAdminModule
}: LoginFormInterface) {
  const [state, setState] = useState({ loading: false, error: '' })
  const [, setSearchParams] = useSearchParams()
  const [data, setData] = useState({ email: '', password: '' })
  const auth = useAuth()
  const login = async function (email: string, password: string) {
    try {
      setState((prev) => ({ ...prev, loading: true, error: '' }))
      await auth.login(email, password)
    } catch (error: any) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: error.message as string
      }))
    }
  }

  useEffect(() => {
    setSearchParams({})
  }, [setSearchParams])

  return (
    <>
      {
        <>
          {state.loading && (
            <CircularProgress sx={{ alignSelf: 'center' }} color="primary" />
          )}
        </>
      }
      <Box sx={state.loading ? { display: 'none' } : {}}>
        <Form
          onSubmit={(formData, resetForm) => {
            const { email, password } = formData
            login(email.toString(), password.toString())
          }}
          autocomplete={false}
        >
          {
            <>
              {Boolean(state.error) && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {state.error}
                </Alert>
              )}
            </>
          }
          <Input
            id="email"
            type="email"
            name="email"
            searchBorder={true}
            label={I18n.general.email}
            placeholder={I18n.general.email}
            required={true}
            onChange={(value) => setData((prev) => ({ ...prev, email: value }))}
            value={data.email}
          />

          <Password
            id="password"
            name="password"
            label={I18n.login.password}
            placeholder={I18n.login.password}
            required={true}
            value={data.password}
            onChange={(value) =>
              setData((prev) => ({ ...prev, password: value }))
            }
          />

          <BlueButton variant={'contained'} type="submit">
            {I18n.login.signin}
          </BlueButton>

          {/* <Button
            variant={'text'}
            sx={{ width: '100%', fontSize: '12px' }}
            onClick={onClickForgotPasswordButton}
          >
            {I18n.login.forgotPassword}
          </Button> */}
          {/* {!isAdminModule ? (
            <>
              <br />
              <Divider variant="middle" />
              <br />
              <Button
                variant={'outlined'}
                sx={{ width: '100%' }}
                onClick={onClickCreateUserButton}
              >
                {I18n.login.createUser}
              </Button>
            </>
          ) : (
            <></>
          )} */}
        </Form>
      </Box>
    </>
  )
}
