export enum AddressTypeEnum {
  AVENUE = 'AVENUE',
  STREET = 'STREET',
  PLAZA = 'PLAZA',
  SQUARE = 'SQUARE',
  PATIO = 'PATIO',
  GARDEN = 'GARDEN'
}

export enum PropertyCategoryEnum {
  COMMERCIAL = 'COMMERCIAL',
  CORPORATE = 'CORPORATE',
  INDUSTRIAL = 'INDUSTRIAL',
  RESIDENTIAL = 'RESIDENTIAL',
  LOGISTICS = 'LOGISTICS',
  ALL = 'ALL',
  NONE = 'NONE'
}

export enum PropertyTypeEnum {
  APARTMENT = 'APARTMENT',
  SOBRADO = 'SOBRADO',
  HOUSE = 'HOUSE',
  LAND = 'LAND',
  FARM = 'FARM',
  KITNET = 'KITNET',
  STUDIO = 'STUDIO',
  RANCH = 'RANCH',
  LOFT = 'LOFT',
  FLAT = 'FLAT',
  GARAGE = 'GARAGE',
  SITIO = 'SITIO',
  LAUNCH = 'LAUNCH',
  TERRACE = 'TERRACE',
  STORE = 'STORE',
  SHED = 'SHED',
  POINT = 'POINT',
  ROOM = 'ROOM',
  NONE = 'NONE'
}

export enum RealStateTypeSubTypeEnum {
  DUPLEX = 'DUPLEX',
  SEMI_DETACHED = 'SEMI_DETACHED',
  STANDARD = 'STANDARD',
  WITH_TERRACE = 'WITH_TERRACE',
  WITHOUT_CONDOMINIUM = 'WITHOUT_CONDOMINIUM',
  NONE = 'NONE'
}

export enum PropertyStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  AVAILABLE = 'AVAILABLE',
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
  PENDING_PHOTO = 'PENDING_PHOTO',
  PROPOSAL_IN_ANALISYS = 'PROPOSAL_IN_ANALISYS',
  RENT = 'RENT'
}
export enum UserTypeEnum {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  CUSTOMER = 'CUSTOMER',
  TENANT = 'TENANT',
  OWNER = 'OWNER'
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum ProposalStatusEnum {
  VALUE_ANALYSIS = 'VALUE_ANALYSIS',
  INSURANCE_ANALYSIS = 'INSURANCE_ANALYSIS',
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  // old entries have this status
  FILLING = "FILLING",
}

export enum ContractStatusEnum {
  FILLING = "FILLING",
  ACTIVE = "ACTIVE",
  IN_VACANCY = "IN_VACANCY",
  IN_LEGAL = "IN_LEGAL",
  TERMINATED = "TERMINATED",
}

export enum TipoCondominioEnum {
  CONDOMINIO_HORIZONTAL = 'CONDOMINIO_HORIZONTAL',
  CONDOMINIO_VERTICAL = 'CONDOMINIO_VERTICAL'
}

export enum ReasonTypeEnum {
  INACTIVATE = 'INACTIVATE',
  REACTIVATE = 'REACTIVATE'
}