import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import I18n from 'common/i18n'
import UsersService from 'common/services/users'
import { UserServiceInterface } from 'common/services/users/interfaces'
import { formatDateString } from 'common/utils/date'
import { UserTypeEnum } from 'common/utils/enums'
import { getErrorMessage } from 'common/utils/errors'
import { cpfCnpjRegex, emailRegex } from 'common/utils/strings'
import Button from 'components/ui/button'
import Input from 'components/ui/form/input'
import { useState } from 'react'
import Checkbox from '../form/checkbox'
import ContactList from './contact-list'
import { UserDataFormInterface, UserDetailFormInterface } from './interfaces'

export default function Steps(props: UserDetailFormInterface) {
  const [activeStep, setActiveStep] = useState(0)
  const [state, setState] = useState({ loading: false, error: '' })
  const userData = props.user || ({} as UserServiceInterface)
  const [data, setData] = useState<UserDataFormInterface>({
    contactList: userData.contactList || [],
    name: userData.name || '',
    email: userData.email || '',
    dateOfBirth: userData.dateOfBirth
      ? formatDateString(new Date(userData.dateOfBirth))
      : '',
    cpfCnpj: userData.cpfCnpj || '',
    rg: userData.rg || '',
    password: '',
    roles: userData.roles || [],
    rentalRoles: userData.rentalRoles || []
  })

  const setField = (value: string, field: string) =>
    setData((prev) => ({ ...prev, [field]: value }))
  const isEdition = Boolean(props.user?.id)
  const isInvalidPassword = (value: string) => {
    const hasUpperCase = /[A-Z]/.test(value)
    const hasLowerCase = /[a-z]/.test(value)
    const hasNumber = /[\d]/.test(value)
    const hasSpecialChar = /[^A-Za-z0-9]/.test(value)
    const hasAtLeast8Chars = value.length >= 8

    return hasAtLeast8Chars &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
      ? ''
      : 'A senha deve conter pelo menos 8 caracteres, 1 letra maiúscula, 1 letra minúscula e 1 caractere especial'
  }

  const verifyPass = () => {
    if (!Boolean(props.user?.id)) {
      return data.password && !isInvalidPassword(data.password) && data.cpfCnpj
    }
    return true
  }

  const steps = [
    {
      label: I18n.users.personalData,
      content: (
        <>
          <Input
            label={I18n.users.cpfCnpj}
            name="cpfCnpj"
            id="cpfCnpj"
            placeholder={I18n.users.cpfCnpj}
            value={data.cpfCnpj}
            maxLength={18}
            minLength={11}
            pattern={cpfCnpjRegex.source}
            onChange={(value) => setField(value, 'cpfCnpj')}
            customValidate={(value) => {
              if (!value) {
                return ''
              }
              return cpfCnpjRegex.test(value) ? '' : I18n.users.errors.cpfFormat
            }}
          />
          <Input
            label={I18n.general.email}
            name="email"
            type="email"
            id="email"
            placeholder={I18n.general.email}
            value={data.email}
            required
            disabled={Boolean(props.user?.id)}
            onChange={(value) => setField(value, 'email')}
          />
          <Input
            name="name"
            id="name"
            placeholder={I18n.users.fullName}
            label={I18n.users.fullName}
            required
            value={data.name}
            onChange={(value) => setField(value, 'name')}
          />
          {props.isCreateCustomer && (
            <Input
              name="password"
              type="password"
              id="password"
              placeholder={'Senha'}
              label={'Senha'}
              required
              value={data.password}
              onChange={(value) => setField(value, 'password')}
              customValidate={isInvalidPassword}
            />
          )}

          <Input
            label={I18n.general.birthdate}
            name="birthdate"
            id="birthdate"
            placeholder={I18n.general.birthdate}
            type="date"
            value={data.dateOfBirth || ''}
            onChange={(value) => setField(value, 'dateOfBirth')}
          />
        </>
      ),
      validation: () => {
        return (
          !!data.name &&
          data.email &&
          emailRegex.test(data.email) &&
          verifyPass() &&
          cpfCnpjRegex.test(data.cpfCnpj)
        )
      }
    },
    {
      label: I18n.users.otherInfo,
      content: (
        <>
          <Checkbox
            id="category"
            name="category"
            showCheckAll={true}
            label={'Você é'}
            onChange={(checked, name) => {
              setData((prev) => {
                if (checked) {
                  // Se checked for true, adiciona o valor em uma das listas
                  if (name === 'PARTNER_BROKER') {
                    prev.roles.push(name)
                    return { ...prev, roles: prev.roles }
                  } else {
                    prev.rentalRoles.push(name)
                    return { ...prev, rentalRoles: prev.rentalRoles }
                  }
                } else {
                  // Se checked for false, remove o valor das listas se ele estiver presente nelas
                  return {
                    ...prev,
                    roles: prev.roles.filter((role) => role !== name),
                    rentalRoles: prev.rentalRoles.filter(
                      (role) => role !== name
                    )
                  }
                }
              })
            }}
            options={[
              {
                text: 'Inquilino',
                name: 'TENANT',
                checked: !!data?.rentalRoles?.find((r) => r === 'TENANT')
              },
              {
                text: 'Propietário',
                name: 'OWNER',
                checked: !!data?.rentalRoles?.find((r) => r === 'OWNER')
              },
              {
                text: 'Corretor parceiro',
                name: 'PARTNER_BROKER',
                checked: !!data?.roles?.find((r) => r === 'PARTNER_BROKER')
              }
            ]}
          />
          <ContactList
            contactList={data.contactList}
            removeContact={(index) => {
              setData((prev) => {
                prev.contactList.splice(index, 1)
                return { ...prev, contactList: prev.contactList }
              })
            }}
            addContact={(contact) => {
              setData((prev) => {
                prev.contactList.push(contact)
                return { ...prev, contactList: prev.contactList }
              })
            }}
          />
        </>
      ),
      validation: () => {
        return true
      }
    }
  ]

  const handleNext = () => {
    const step = steps[activeStep]
    if (step.validation()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const isLastStep = (index: number) => index === steps.length - 1

  const saveUser = async () => {
    try {
      setState((prev) => ({ ...prev, error: '', loading: true }))
      const dataUser = {
        ...data,
        cpfCnpj: data.cpfCnpj.replaceAll(/\D/gim, '')
      }
      // removing password when editing user to no pass to the backend
      if (!props.isCreateCustomer) {
        delete dataUser.password
      }
      let updatedUser = null
      let auth = ''
      let refreshToken = ''
      if (isEdition) {
        updatedUser = await UsersService.editUser({
          ...dataUser,
          id: props.user?.id
        })
      } else {
        if (props.isCreateCustomer) {
          const payload = await UsersService.createCustomer({
            ...dataUser
          } as Omit<UserServiceInterface, 'id' | 'status'>)

          updatedUser = payload.user
          auth = payload.auth
          refreshToken = payload.refreshToken
        } else {
          updatedUser = await UsersService.createUser({
            ...dataUser,
            roles: [UserTypeEnum.CUSTOMER],
            rentalRoles: [],
            rentedIDs: [],
            realStatesIDs: []
          } as Omit<UserServiceInterface, 'id' | 'status'>)
        }
      }
      props.onSuccess(updatedUser, auth, refreshToken)
      props.onClose()
    } catch (err: any) {
      const errorMappers = {
        UsernameExistsException: 'Usuário já existe',
        PasswordResetRequiredException: 'Necessário resetar sua senha',
        InvalidPasswordFormatException:
          'A senha deve conter pelo menos 8 caracteres, 1 letra maiúscula, 1 letra minúscula e 1 caractere especial'
      } as Record<string, string>
      let errorMessage =
        errorMappers[err.exception as string] || err.exception || err.message
      setState((prev) => ({ ...prev, error: errorMessage }))
    } finally {
      setState((prev) => ({ ...prev, loading: false }))
    }
  }

  if (state.loading) {
    return (
      <CircularProgress sx={{ m: 'auto', display: 'flex' }} color="primary" />
    )
  }

  return (
    <>
      <>
        {Boolean(state.error) && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {getErrorMessage(state.error)}
          </Alert>
        )}
      </>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        alternativeLabel={true}
      >
        {steps.map((step, index) => {
          const lastStep = isLastStep(index)
          return (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                {step.content}

                <Stack
                  direction="row"
                  sx={{ justifyContent: 'space-between', mb: 2 }}
                >
                  <Button
                    disabled={index === 0 && !props.onCancel}
                    onClick={index === 0 ? props.onCancel : handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === 0 && props.onCancel
                      ? I18n.general.cancel
                      : I18n.general.back}
                  </Button>

                  <Button
                    variant="contained"
                    onClick={lastStep ? saveUser : handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={!step.validation()}
                    color={lastStep ? 'success' : 'primary'}
                  >
                    {lastStep ? I18n.general.save : I18n.general.next}
                  </Button>
                </Stack>
              </StepContent>
            </Step>
          )
        })}
      </Stepper>
    </>
  )
}
