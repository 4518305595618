export const cpfRegex = /^[0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2}$/

export const cnpjRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/

export const cpfCnpjRegex =
  /^(\d{3}\.?\d{3}\.?\d{3}-?\d{2}|\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const zipCodeRegex = /^[\d]{2}\.?[\d]{3}-?[\d]{3}$/

export const currencyRegex = /^[\d]+([,.][\d]{2})?$/

export const floatRegex = /^[\d]+([,.][\d]{1,2})?$/

// Regex não funciona sem a \ no hífen, e o eslint reclama
/* eslint-disable-next-line no-useless-escape */
export const nameRegex = /^[A-Za-zÀ-ÿ'_ \-]+$/

export const formatCpf = (value: string | undefined): string => {
  if (!value) {
    return ''
  }

  return value
    .replace(/[^\d]/g, '')
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatCPFCNPJ = (value: string | undefined): string => {
  if (!value) {
    return ''
  }

  const cleanValue = value.replace(/[^\d]/g, '')

  if (cleanValue.length === 11) {
    // Formatar CPF (123.456.789-10)
    return cleanValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  } else if (cleanValue.length === 14) {
    // Formatar CNPJ (12.345.678/0001-90)
    return cleanValue.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    )
  } else {
    // Se o tamanho não for 11 nem 14, retorna o valor original (sem formatação)
    return value
  }
}

export const formatPhone = (value: string | undefined): string => {
  if (!value) {
    return ''
  }
  const formattedValue = value.replace(/[^\d]/g, '')

  return formattedValue.length === 10
    ? formattedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3')
    : formattedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3')
}

export const isTrueCheckbox = (value: FormDataEntryValue) => {
  if (!value) {
    return false
  }
  return value.toString() === 'on'
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const capitalizeWord = (value: string) => {
  if (!value) return ''

  return value[0].toUpperCase() + value.slice(1).toLowerCase()
}

export const capitelizeAllWords = (value: string) => {
  if (!value) return ''

  return value
    .split(' ')
    .map((item) => capitalizeWord(item))
    .join(' ')
}
