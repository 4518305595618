import { useEffect, useState } from 'react'
import I18n from 'common/i18n'
import AuthService from 'common/services/auth'
import Input from 'components/ui/form/input'
import Button from 'components/ui/button'
import Form from 'components/ui/form'
import { ForgotPasswordFormInterface } from './interfaces'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import Box from '@mui/system/Box'
import { useSearchParams } from 'react-router-dom'

export default function ForgotPasswordForm({
  onClickLoginButton,
  goToConfirmPassword: goToResetPassword
}: ForgotPasswordFormInterface) {
  let [searchParams, setSearchParams] = useSearchParams()
  const [state, setState] = useState({ loading: false, error: '' })
  const [email, setEmail] = useState('')

  const forgetPassword = async function (email: string) {
    try {
      setState((prev) => ({ ...prev, loading: true, error: '' }))
      await AuthService.forgetPassword(email)
      setSearchParams({ email })
      goToResetPassword()
    } catch (error: any) {
      setState((prev) => ({
        ...prev,
        error: error.message as string
      }))
    } finally {
      setState((prev) => ({ ...prev, loading: false }))
    }
  }

  useEffect(() => {
    setEmail(searchParams.get('email') || '')
  }, [searchParams])

  return (
    <>
      {state.loading && (
        <CircularProgress sx={{ alignSelf: 'center' }} color="primary" />
      )}
      <Box sx={state.loading ? { display: 'none' } : {}}>
        <Form
          onSubmit={(formData) => {
            forgetPassword(formData.email.toString())
          }}
          autocomplete={false}
        >
          {
            <>
              {Boolean(state.error) && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {state.error}
                </Alert>
              )}
            </>
          }
          <Input
            id="email"
            type="email"
            name="email"
            label={I18n.general.email}
            placeholder={I18n.general.email}
            required={true}
            value={email}
            onChange={(value) => setEmail(value)}
          />
          <Button
            variant={'contained'}
            sx={{ width: '100%', marginBottom: '1rem' }}
            type="submit"
          >
            {I18n.login.recoverPassword}
          </Button>
          <Button
            variant={'text'}
            sx={{ width: '100%' }}
            onClick={onClickLoginButton}
          >
            {I18n.general.back}
          </Button>
        </Form>
      </Box>
    </>
  )
}
