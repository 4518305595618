export enum LoginActionEnum {
  login,
  forgotPassword,
  confirmPassword,
  createUser,
  loadingPermisions
}
export type LoginActionType =
  | LoginActionEnum.login
  | LoginActionEnum.forgotPassword
  | LoginActionEnum.confirmPassword
  | LoginActionEnum.createUser
  | LoginActionEnum.loadingPermisions


export interface LoginFormInterface {
  isAdminModule: boolean
  onClickForgotPasswordButton: React.MouseEventHandler<HTMLButtonElement>
  onClickCreateUserButton: React.MouseEventHandler<HTMLButtonElement>
}

export interface ForgotPasswordFormInterface {
  isAdminModule: boolean
  onClickLoginButton: React.MouseEventHandler<HTMLButtonElement>
  goToConfirmPassword: () => void
}

export interface ConfirmForgotPasswordFormInterface {
  isAdminModule: boolean
  onClickForgotPasswordButton: React.MouseEventHandler<HTMLButtonElement>
  gotoLogin: () => void
}
