const NumberToText = require('extenso')

export function parseInteger(value: string | FormDataEntryValue): number {
  if (!value) {
    return 0
  }

  return parseInt(value.toString())
}

export function parseDouble(value: string | FormDataEntryValue): number {
  if (!value) {
    return 0
  }

  return parseFloat(value.toString())
}

export const formatPercentage = (value: number): string => {
  if (!value) {
    return '0,00'
  }
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formatter.format(value)
}

export const formatIntegerToHaveTwoDigits = (value: number): string =>
  ('0' + value).slice(-2)

export const formatNumberToText = (value: number | string, options?: { mode?: 'currency' }) => {
  return NumberToText(value, options)
}