import { ContactListInterface } from './interfaces'

import List from '@mui/material/List'
import Input from 'components/ui/form/input'
import I18n from 'common/i18n'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'
import Button from 'components/ui/button'
import Box from '@mui/system/Box'
import useTheme from '@mui/material/styles/useTheme'
import { useState, useEffect } from 'react'
import Alert from '@mui/material/Alert'
import { formatPhone } from 'common/utils/strings'

export default function ContactList(props: ContactListInterface) {
  const { contactList, addContact, removeContact } = props
  const theme = useTheme()
  const [currentContact, setCurrentContact] = useState<string>('')
  const [clear, setClear] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const isValidContact = (value: string): boolean => {
    const normalizedValue = value.replaceAll(/()-/gim, '')
    return (
      (normalizedValue.length === 11 && /[0-9]{11}/.test(normalizedValue)) ||
      (normalizedValue.length === 10 && /[0-9]{10}/.test(normalizedValue))
    )
  }

  useEffect(() => {
    setCurrentContact('')
    setClear(false)
  }, [clear])

  return (
    <Grid item xs={12}>
      {
        <>
          {Boolean(error) && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}
        </>
      }
      <Input
        label={I18n.general.contact}
        name="contact"
        id="contact"
        placeholder={'(XX)YXXXX-XXXX'}
        maxLength={14}
        minLength={10}
        type="phone"
        onChange={(value) => setCurrentContact(value)}
        value={currentContact}
        customValidate={(value) => {
          return isValidContact(value) ? '' : I18n.users.errors.validContact
        }}
        endAdornment={
          <InputAdornment position="end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (contactList.includes(currentContact)) {
                  setClear(true);
                  setError(I18n.users.errors.duplicateContact);
                  return;
                }
                if (isValidContact(currentContact)) {
                  setError('')
                  addContact(currentContact.replaceAll(/\D/gim, ''));
                } else {
                  setError(I18n.users.errors.validContact);
                }
                setClear(true);
              }}
            >
              <AddIcon />
            </Button>
          </InputAdornment>
        }
      />
      {contactList.length === 0 && <p>{I18n.users.noPhones}</p>}
      {Boolean(contactList.length) && (
        <Box sx={{ backgroundColor: theme.palette.background.paper }}>
          <List id={"contacts"} dense={true}>
            {contactList.map((item, index) => {
              return (
                <ListItem
                  key={item}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeContact(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={formatPhone(item)} />
                </ListItem>
              )
            })}
          </List>
        </Box>
      )}
    </Grid>
  )
}
