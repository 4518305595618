import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/system/Box'
import I18n from 'common/i18n'
import AuthService from 'common/services/auth'
import Button from 'components/ui/button'
import Form from 'components/ui/form'
import { useState } from 'react'
import { ConfirmForgotPasswordFormInterface } from '../interfaces'
import Fields from './fields'

export default function ConfirmForgotPasswordForm({
  onClickForgotPasswordButton,
  gotoLogin
}: ConfirmForgotPasswordFormInterface) {
  const [state, setState] = useState({ loading: false, error: '' })
  const resetPassword = async function (
    email: string,
    code: string,
    password: string
  ) {
    try {
      setState((prev) => ({ ...prev, loading: true, error: '' }))
      await AuthService.resetPassword(email, code, password)
      gotoLogin()
    } catch (error: any) {
      setState((prev) => ({
        ...prev,
        error: error.message as string
      }))
    } finally {
      setState((prev) => ({ ...prev, loading: false }))
    }
  }

  return (
    <>
      {state.loading && (
        <CircularProgress sx={{ alignSelf: 'center' }} color="primary" />
      )}
      <Box sx={state.loading ? { display: 'none' } : {}}>
        <Form
          onSubmit={(formData) => {
            resetPassword(
              formData.email.toString(),
              formData.code.toString(),
              formData['confirm-new-password'].toString()
            )
          }}
          autocomplete={false}
        >
          {
            <>
              {Boolean(state.error) && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {state.error}
                </Alert>
              )}
            </>
          }
          <Fields />
          <Button
            variant={'contained'}
            sx={{ width: '100%', marginBottom: '1rem' }}
            type="submit"
          >
            {I18n.login.recoverPassword}
          </Button>
          <Button
            variant={'text'}
            sx={{ width: '100%' }}
            onClick={onClickForgotPasswordButton}
          >
            {I18n.general.back}
          </Button>
        </Form>
      </Box>
    </>
  )
}
