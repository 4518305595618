import Box from '@mui/material/Box'
import I18n from 'common/i18n'
import { LogoInterface, LogoTypeEnum } from './interfaces'

const Logo = (props: LogoInterface) => {
  const getSrcImage = (type: string): string => {
    const imagePath = '/assets/svg'
    const imageMapper = {
      [LogoTypeEnum.DARK]: 'logo_escura.svg',
      [LogoTypeEnum.LIGHT]: 'logo_clara.svg'
    } as Record<string, string>

    return `${imagePath}/${imageMapper[type]}`
  }
  return (
    <>
      <Box
        onClick={() => props.rauter()}
        sx={{
          display: 'flex',
          alignSelf: 'center',
          flexDirection: 'column',
          cursor: 'pointer'
        }}
        component={'p'}
      >
        <img
          src={getSrcImage(props.type)}
          alt={I18n.general.logo}
          width="300px"
          style={{ margin: 'auto', maxWidth: '100%' }}
        />
      </Box>
    </>
  )
}

export default Logo
