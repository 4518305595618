import { useCallback, useEffect, useState } from 'react'
import i18n from 'common/i18n'
import {
  ValidationFormErrorInterface,
  ErrorMappingInterface
} from 'common/i18n/interfaces'
import {
  UseValidationInterface,
  UseValidationPropsInterface
} from './interfaces'

function useValidation({
  customValidate,
  disabled,
  min,
  minLength,
  max,
  maxLength,
  name,
  noValidate,
  ref,
  step,
  type,
  multiline
}: UseValidationPropsInterface): UseValidationInterface {
  const [, setValidity] = useState({
    badInput: false,
    customError: false,
    patternMismatch: false,
    rangeOverflow: false,
    rangeUnderflow: false,
    stepMismatch: false,
    tooLong: false,
    tooShort: false,
    typeMismatch: false,
    valid: true,
    valueMissing: false
  })
  const [, setBlur] = useState<boolean>(false)
  const [, setUpdated] = useState<boolean>(false)
  const [error, setError] = useState('')
  const getErrorMessage = useCallback(
    (validity: ValidityState): string => {
      let error = ''
      if (!disabled) {
        for (let key in validity) {
          if (key !== 'valid' && validity[key as keyof ValidityState]) {
            error = key
            break
          }
        }

        if (error && error !== 'customError') {
          const errorMapping = i18n.form.errors as ErrorMappingInterface
          const errorParams = {
            min,
            minLength,
            max,
            maxLength,
            inputName: name,
            step,
            inputType: type
          } as ValidationFormErrorInterface

          const getMessage = errorMapping[
            error as keyof ErrorMappingInterface
          ] as any

          return getMessage(errorParams)
        }
        if (customValidate) {
          error = customValidate(ref.current?.value || '') || ''
        } else {
          error = ''
        }

        if (ref.current?.setCustomValidity) {
          ref.current?.setCustomValidity(error)
        }
      }
      return error
    },
    [
      customValidate,
      ref,
      disabled,
      max,
      maxLength,
      min,
      minLength,
      name,
      step,
      type,
      multiline,
    ]
  )
  const updateValidity = useCallback(
    (value: string, validity: ValidityState): void => {
      if (noValidate) {
        return
      }

      setValidity(validity)

      if (validity.valid) {
        const customError = (customValidate && customValidate(value)) || ''
        customError && ref.current?.setCustomValidity(customError)
      }

      if (!validity.valid) {
        setError(getErrorMessage(validity))
      } else {
        setError('')
      }
    },
    [noValidate, customValidate, ref, getErrorMessage]
  )

  useEffect(() => {
    const input = ref.current as unknown as
      | HTMLInputElement
      | HTMLTextAreaElement

    const onValidateHandler = (event: Event): void => {
      const input = event.target as unknown as
        | HTMLInputElement
        | HTMLTextAreaElement

      updateValidity(input.value, input.validity)
    }

    // Validates searchSelect that doesn’t have the form value.
    const onValidateFormSearchSelectHandler = (event: Event): void => {
      // Force useState rendering to show the validation message, even with the accordion closed
      setTimeout(() => {
        const input = event.target as unknown as
        | HTMLInputElement
        | HTMLTextAreaElement
        input.removeAttribute('hidden');
        input.focus();
        input.blur();
        input.setAttribute('hidden', 'true');
      }, 0)
    }

    if (input) {
      input.addEventListener(
        'validate',
        onValidateHandler as unknown as EventListener
      )
      if(input.classList.contains('input-value-form-for-search-select-validate')) {
        input.addEventListener('validate-form-search-select-value', onValidateFormSearchSelectHandler)
      }
    }
    return (): void => {
      if (input) {
        input.removeEventListener(
          'validate',
          onValidateHandler as unknown as EventListener
        )
      }
    }
  }, [ref, updateValidity])

  return {
    error,
    updateValidity,
    setBlur,
    setUpdated
  }
}

export default useValidation
