import './App.scss'

import Router from 'router'
import Theme from './styles/theme-admin'
import AuthProvider from 'common/auth/auth-provider'
import AuthModule from 'common/auth'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthUserInterface } from 'common/auth/interfaces'
import { useEffect, useState } from 'react'
import Login from 'pages/login'
import { LoginActionEnum } from 'pages/login/interfaces'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function App() {

  // TODO: Remover useStates, loading e useEffect quando colocar as permission no AWS
  const [auth, setAuth] = useState<AuthUserInterface | null>(null)
  const [loadPermissions, setLoadPermissions] = useState<boolean>(true)

  useEffect(() => {
    const getAuth = async () => {
      try {
        setLoadPermissions(true)
        setAuth(await AuthModule.getAuthData())
      } catch (_) {
      } finally {
        setLoadPermissions(false)
      }
    }
    getAuth()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      { loadPermissions ? <Login initialAction={LoginActionEnum.loadingPermisions} /> : (
        <AuthProvider auth={auth}>
          <Theme>
            <Router />
          </Theme>
        </AuthProvider>
      )}
    </QueryClientProvider>
  )
}

export default App
