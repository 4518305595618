import { useContext, useEffect, useState } from 'react'
import I18n from 'common/i18n'
import Input from 'components/ui/form/input'
import Password from 'components/ui/form/password'
import { FormContext } from 'components/ui/form'
import { useSearchParams } from 'react-router-dom'

const PASSWORD_LENGTH = 8

export default function Fields() {
  const formContext = useContext(FormContext)
  const [searchParams] = useSearchParams()
  const [form, setForm] = useState({
    email: '',
    code: '',
    newPassword: '',
    confirmNewPassword: ''
  })

  useEffect(() => {
    setForm((prev) => ({ ...prev, email: searchParams.get('email') || '' }))
  }, [searchParams])
  return (
    <>
      <Input
        id="email"
        type="email"
        name="email"
        label={I18n.general.email}
        placeholder={I18n.general.email}
        required={true}
        value={form.email}
        onChange={(email) => setForm((prev) => ({ ...prev, email }))}
      />
      <Input
        id="code"
        name="code"
        label={I18n.general.code}
        placeholder={I18n.general.code}
        required={true}
        onChange={(code) => setForm((prev) => ({ ...prev, code }))}
      />
      <Password
        id="new-password"
        name="new-password"
        label={I18n.login.newPassword}
        placeholder={I18n.login.newPassword}
        required={true}
        customValidate={(val) => {
          if (val.length < PASSWORD_LENGTH) {
            return I18n.login.errors.passwordMustHaveLength(PASSWORD_LENGTH)
          }
          if (!/\d/.test(val)) {
            return I18n.login.errors.passwordMustContainNumber
          }
          if (!/[a-z]/.test(val)) {
            return I18n.login.errors.passwordMustContainLowerCase
          }
          if (!/[A-Z]/.test(val)) {
            return I18n.login.errors.passwordMustContainUpperCase
          }

          const formData = formContext.getData()
          if (
            formData['confirm-new-password'] &&
            formData['confirm-new-password'] !== val
          ) {
            return I18n.login.errors.passwordMustMatch
          }
        }}
        onChange={(newPassword) =>
          setForm((prev) => ({ ...prev, newPassword }))
        }
      />
      <Password
        id="confirm-new-password"
        name="confirm-new-password"
        label={I18n.login.confirmNewPassword}
        placeholder={I18n.login.confirmNewPassword}
        required={true}
        customValidate={(val) => {
          const formData = formContext.getData()
          if (formData['new-password'] && formData['new-password'] !== val) {
            return I18n.login.errors.passwordMustMatch
          }
        }}
        onChange={(confirmNewPassword) =>
          setForm((prev) => ({ ...prev, confirmNewPassword }))
        }
      />
    </>
  )
}
