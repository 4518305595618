import Constants from 'common/constants'
import { AuthUserInterface, JWTTokenInterface } from './interfaces'
import config from 'common/config'
import Http from 'common/http'
import { Permissoes } from 'pages/admin/access/interfaces'

const LOCAL_STORAGE_KEY = 'auth'

function parseJwt(token: string): JWTTokenInterface {
  try {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  } catch {
    return {
      'custom:roles': '',
      auth_time: 0,
      exp: 0,
      iat: 0,
      email: '',
      email_verified: false,
      iss: '',
      aud: '',
      'cognito:username': '',
      jti: '',
      origin_jti: '',
      sub: '',
      token_use: ''
    }
  }
}

const isTokenValid = (jwt: JWTTokenInterface) => {
  const expirationTimeInMiliseconds = jwt.exp * 1000
  const now = Date.now()
  return now < expirationTimeInMiliseconds
}

const isEmployee = (jwt?: JWTTokenInterface) => {
  return jwt ? jwt['custom:roles'] === Constants.ROLES.EMPLOYEE : false
}
const isAdmin = (jwt?: JWTTokenInterface) => {
  return jwt ? jwt['custom:roles'] === Constants.ROLES.ADMIN : false
}
const isCustomer = (jwt?: JWTTokenInterface) => {
  return jwt ? jwt['custom:roles'] === Constants.ROLES.CUSTOMER : false
}

const isPartnerBroker = (jwt?: JWTTokenInterface) => {
  return jwt
    ? jwt['custom:roles'].includes(Constants.ROLES.PARTNER_BROKER)
    : false
}

// TODO: MAPEAR PERMISSÕES NO TOKEN AWS QUANDO TIVER ACESSO, PARA PARAR DE PUXAR ENDPOINTS
async function getPermissions(): Promise<Permissoes[]> {
  return Http.get<Permissoes[]>(`${config.API_URL}/${Constants.API_URl_PREFIX}/users/permissions/active-permissions`)
}

const AuthModule = {
  parseJwt,
  isAdmin,
  isCustomer,
  isEmployee,
  isTokenValid,
  isPartnerBroker,
  getPermissions,
  // TODO: DEIXAR SINCRONO QUANDO COLOCAR AS PERMISSIONS NO AWS
  getAuthData: async (): Promise<AuthUserInterface | null> => {
    try {
      const auth = localStorage.getItem(LOCAL_STORAGE_KEY)
      if (auth) {
        const authData = JSON.parse(auth)
        const jwt = parseJwt(authData?.accessToken)
        const permissions = await getPermissions()
        return {
          jwt,
          authData,
          permissions
        }
      }

      return null
    } catch {
      return null
    }
  }
}

export default AuthModule
