export const Colors = {
  azul: '#457B9D',
  azulSecundario: '#58829D',
  azulTerciario: '#4E748B',
  azulQuaternario: '#2f5c78',
  fonte: '#363636',
  cinza: '#DCDCDC',
  cinzaSegundario: '#A9A9A9',
  vermelhoError: '#970700',
}
