import React, {
  createContext,
  useRef,
  useEffect,
  ReactElement,
  useCallback
} from 'react'
import {
  FormContextInterface,
  FormDataObjectInterface,
  FormProviderInterface
} from './interfaces'
import Box from '@mui/system/Box'

const FORM_SUBMITTED_CLASS = 'form-submitted'

const FormContext = createContext<FormContextInterface>({
  form: null,
  getData: () => ({})
})

function FormProvider({
  onInvalid,
  onSubmit,
  validateOnRender,
  autocomplete,
  encType,
  id,
  method,
  children,
  scrollToInvalidField,
  externalRef,
  hasEnterSubmit = true
}: FormProviderInterface): ReactElement {
  const internalRef = useRef<HTMLFormElement>(null)
  const formRef = externalRef || internalRef
  const onResetHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    const form = e.target as HTMLFormElement
    const elements = form.elements

    // Dispatching reset event to fields
    for (let i = 0; i < elements.length; i++) {
      elements[i].dispatchEvent(new Event('reset'))
    }

    form.classList.remove(FORM_SUBMITTED_CLASS)
  }

  const validateForm = useCallback(
    (form: HTMLFormElement): boolean => {
      const elements = form.elements

      // Dispatching custom event to fields
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i]
        element.dispatchEvent(new CustomEvent('validate'))
        if(element instanceof HTMLInputElement && element.classList.contains('input-value-form-for-search-select-validate')) {
            element.dispatchEvent(new CustomEvent('validate-form-search-select-value'))
          }
      }

      const isValid = form.checkValidity()
      if (!isValid) {
        onInvalid && onInvalid(form.querySelectorAll(':invalid'))
        const firstInvalidElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null = form.querySelector(':invalid')
        if (firstInvalidElement && scrollToInvalidField) {
          const labelOffset = 50
          const topPosition =
            firstInvalidElement.getBoundingClientRect().top +
            window.scrollY -
            labelOffset
          window.scroll({
            top: topPosition,
            left: 0,
            behavior: 'smooth'
          })
          setTimeout(() => firstInvalidElement.focus(), 1000)
        }
      }

      return isValid
    },
    [onInvalid, scrollToInvalidField]
  )

  function getData(): FormDataObjectInterface {
    const formData = new FormData(formRef.current || undefined)

    const data = {} as FormDataObjectInterface
    formData.forEach((value, key) => {
      data[key] = value
    })

    return data
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    try {
      const form = e.target as HTMLFormElement
      form.classList.add(FORM_SUBMITTED_CLASS)
      if (validateForm(form)) {
        const formData: FormDataObjectInterface = getData()

        onSubmit(formData, () => form.reset())
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (validateOnRender && formRef.current) {
      validateForm(formRef.current)
    }
  }, [validateOnRender, validateForm])

  return (
    <form
      autoComplete={autocomplete ? 'on' : 'off'}
      className="form"
      encType={encType}
      id={id}
      method={method}
      noValidate
      ref={formRef}
      onSubmit={onSubmitHandler}
      onReset={onResetHandler}
      onKeyDown={(e) => {if(!hasEnterSubmit && e.key === 'Enter') e.preventDefault()}}
    >
      <FormContext.Provider value={{ form: formRef.current, getData }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {children}
        </Box>
      </FormContext.Provider>
    </form>
  )
}

export { FormContext, FORM_SUBMITTED_CLASS }
export default FormProvider
