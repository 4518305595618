import CheckboxMUI from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Box from '@mui/system/Box'
import useValidation from 'common/hooks/useValidation'
import i18n from 'common/i18n'
import Button from 'components/ui/button'
import { useRef } from 'react'
import { CheckboxInterface } from './interfaces'

export default function Checkbox(props: CheckboxInterface) {
  const ref = useRef<HTMLInputElement>()
  const { error, updateValidity, setUpdated } = useValidation({
    customValidate: props.customValidate,
    name: props.name,
    noValidate: props.noValidate,
    ref,
    disabled: props.disabled
  })

  const areAllItemsChecked = props.options.every((item) => item.checked)
  return (
    <FormControl
      required={props.required}
      variant="standard"
      error={Boolean(error)}
    >
      <FormLabel
        sx={{ color: 'primary.main', fontWeight: 'bold' }}
        component="legend"
      >
        {props.label}
      </FormLabel>
      <FormGroup style={{ flexDirection: 'column' }}>
        {props.options.map((item) => {
          return (
            <FormControlLabel
              inputRef={ref}
              key={`${props.id}_${item.name}`}
              control={
                <CheckboxMUI
                  id={props.id}
                  disabled={props.disabled}
                  checked={item.checked || false}
                  onChange={(e) => {
                    const { validity, checked, name } = e.target
                    setUpdated(true)
                    updateValidity(checked.toString(), validity)
                    props.onChange && props.onChange(checked, name)
                  }}
                  name={item.name}
                />
              }
              label={item.text || item.name}
            />
          )
        })}
        {props.showCheckAll && (
          <Box sx={{ width: 155 }}>
            <Button
              variant="text"
              onClick={() => {
                const value = areAllItemsChecked ? false : true
                props.options.forEach((item) => {
                  props.onChange && props.onChange(value, item.name)
                })
              }}
              size="small"
            >
              {areAllItemsChecked
                ? i18n.general.uncheckAll
                : i18n.general.checkAll}
            </Button>
          </Box>
        )}
      </FormGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}
