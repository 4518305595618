import Constants from 'common/constants'
import AuthService from 'common/services/auth'
import { createContext, useCallback, useContext, useState } from 'react'
import { setRedirectRoute } from 'router/redirect'
import AuthModule from '.'
import {
  AuthProviderInterface,
  AuthUserContextInterface,
  AuthUserInterface
} from './interfaces'

const AuthContext = createContext<AuthUserContextInterface>({
  setAuth: () => {},
  clearAuth: () => {},
  auth: null
})

function AuthProvider(props: AuthProviderInterface) {
  const [state, setState] = useState(props.auth)
  return (
    <AuthContext.Provider
      value={{
        auth: state,
        clearAuth: () => setState(null),
        setAuth: (auth: AuthUserInterface) => setState(auth)
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)

  const logout = useCallback(() => {
    context.clearAuth()
    localStorage.removeItem(Constants.AUTH_LOCAL_STORAGE)
    localStorage.removeItem(Constants.TOKEN_LOCAL_STORAGE)
    setRedirectRoute()
  }, [context])

  const isAuthenticated = useCallback(() => {
    if (!context.auth) {
      return false
    }

    let isTokenValid = AuthModule.isTokenValid(context.auth.jwt)
    if (!isTokenValid) {
      setRedirectRoute()
    }
    return isTokenValid
  }, [context])

  const loginWithToken = async (accessToken: string, refreshToken: string) => {
    localStorage.setItem(
      Constants.AUTH_LOCAL_STORAGE,
      JSON.stringify({ accessToken, refreshToken })
    )
    localStorage.setItem(Constants.TOKEN_LOCAL_STORAGE, accessToken)
    const jwt = AuthModule.parseJwt(accessToken)
    // TODO: REMOVER QUANDO COLOCAR AS PERMISSIONS NO AWS
    const permissions = await AuthModule.getPermissions()

    if (jwt) {
      context.setAuth({
        jwt,
        authData: {
          accessToken,
          refreshToken
        },
        permissions
      })
    }
  }

  const login = async (email: string, password: string) => {
    const { accessToken, refreshToken } = await AuthService.login(
      email,
      password
    )

    loginWithToken(accessToken, refreshToken)
  }

  return {
    isAdmin: AuthModule.isAdmin(context.auth?.jwt),
    isEmployee:
      AuthModule.isEmployee(context.auth?.jwt) ||
      AuthModule.isAdmin(context.auth?.jwt),
    isCustomer: AuthModule.isCustomer(context.auth?.jwt),
    isPartnerBroker: AuthModule.isPartnerBroker(context.auth?.jwt),
    isAuthenticated,
    auth: context.auth,
    logout,
    login,
    loginWithToken
  }
}

export { AuthContext, useAuth }
export default AuthProvider
